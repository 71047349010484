import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { UserConversion } from '../../users/conversion/user.conversion';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import {
  ControlDetailsDTO,
  ControlFormDTO,
  ControlListDTO,
  ControlMinimalDetailsDTO,
} from '../dto/control.dto';
import { FormGroup } from '@angular/forms';
import { convertResponseToControlTypeDetailsDTO } from '../../../settings/organization/dto/control-type.dto';
import { convertResponseToControlCategoryDetailsDTO } from '../../../settings/organization/dto/control-category.dto';
import { convertResponseToControlImplementationStatusDetailsDTO } from '../../../settings/organization/dto/control-implementation-status.dto';
import { convertResponseToControlEfficiencyStatusDetailsDTO } from '../../../settings/organization/dto/control-efficiency-status.dto';
import { convertResponseToReviewFrequencyDTO } from '../../../settings/masters/dto/review-frequency.dto';
import { convertResponseToControlTestDetailsDTO } from '../dto/control-test.dto';
import { convertResponseToLifeCycleStatusDetailsDTO } from '../../../settings/masters/dto/life-cycle-status.dto';

@Injectable({
  providedIn: 'root',
})
export class ControlConversion
  implements
    MainConversionInterface<ControlListDTO, ControlFormDTO, ControlDetailsDTO>
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion,
    private userConv: UserConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToList(response: any[]): ControlListDTO[] {
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id,
        reference_number: item.reference_number,
        code: item.code,
        title: item[`title_${this.lang}`],
        description: item.description,
        objective: item.objective,
        control_type_title: item[`control_type_title_${this.lang}`],
        control_category_title: item[`control_category_title_${this.lang}`],
        owner: this.userConv.resToUserMinimalDTO(item, 'owner'),
        control_implementation_status: this.tableConv.resToColordItem(
          item,
          'control_implementation_status'
        ),
        control_efficiency_status: this.tableConv.resToColordItem(
          item,
          'control_efficiency_status'
        ),
        implemented_at: item.implemented_at,
        review_frequency_title: item[`review_frequency_title_${this.lang}`],
        last_review_date: item.last_review_date,
        last_review_note: item.last_review_note,
        next_review_date: item.next_review_date,
        test_responsible_user: this.userConv.resToUserMinimalDTO(
          item,
          'test_responsible_user'
        ),
        test_frequency_title: item[`test_frequency_title_${this.lang}`],
        last_test_date: item.last_test_date,
        next_test_date: item.next_test_date,
        created_at: item.created_at,
        updated_at: item.updated_at,
        created_user: this.userConv.resToUserMinimalDTO(item, 'created_user'),
        updated_user: this.userConv.resToUserMinimalDTO(item, 'updated_user'),
        status: this.tableConv.resToColordItem(item, 'lifecycle_status'),
      };
    });
    return result;
  }

  formGroupToForm(formGroup: FormGroup): ControlFormDTO {
    return {
      id: formGroup.get('id')?.value,
      control_type_id: formGroup.get('ControlType')?.value,
      control_category_id: formGroup.get('ControlCategory')?.value,
      //owner_unit_id: formGroup.get('OwnerUnitID')?.value,
      owner_unit_id: 1,
      owner_id: formGroup.get('Owner')?.value,
      control_implementation_status_id: formGroup.get(
        'ControlImplementationStatus'
      )?.value,
      control_efficiency_status_id: formGroup.get('ControlEfficiencyStatus')
        ?.value,
      code: formGroup.get('Code')?.value,
      title_en: formGroup.get('Title')?.value,
      title_ar: formGroup.get('Title')?.value,
      description: formGroup.get('Description')?.value,
      objective: formGroup.get('Objective')?.value,
      review_frequency_id: formGroup.get('ReviewFrequency')?.value,
      last_review_date: formGroup.get('LastReviewDate')?.value,
      next_review_date: formGroup.get('NextReviewDate')?.value,
      last_review_note: formGroup.get('LastReviewNote')?.value,
      test_responsible_user_id: formGroup.get('TestResponsibleUser')?.value,
      test_frequency_id: formGroup.get('TestFrequency')?.value,
      last_test_date: formGroup.get('LastTestDate')?.value,
      next_test_date: formGroup.get('NextTestDate')?.value,
      implemented_at: formGroup.get('ImplementedAt')?.value,
    };
  }

  resToForm(response: any): any {
    return {
      id: response.id,
      ControlType: response.control_type?.id,
      ControlCategory: response.control_category?.id,
      OwnerUnit: response.owner_unit?.id,
      Owner: response.owner?.id,
      ControlImplementationStatus: response.control_implementation_status?.id,
      ControlEfficiencyStatus: response.control_efficiency_status?.id,
      Code: response.code,
      Title: response.title_en,
      Description: response.description,
      Objective: response.objective,
      ReviewFrequency: response.review_frequency?.id,
      LastReviewDate: response.last_review_date,
      NextReviewDate: response.next_review_date,
      LastReviewNote: response.last_review_note,
      TestResponsibleUser: response.test_responsible_user?.id,
      TestFrequency: response.test_frequency?.id,
      LastTestDate: response.last_test_date,
      NextTestDate: response.next_test_date,
      ImplementedAt: response.implemented_at,
    };
  }

  resToDetails(response: any): ControlDetailsDTO {
    return {
      id: response.id,
      reference_number: response.reference_number,
      code: response.code,
      title: response[`title_${this.lang}`],
      description: response.description,
      objective: response.objective,
      control_type: response.control_type
        ? convertResponseToControlTypeDetailsDTO(
            response.control_type,
            this.lang
          )
        : null,
      control_category: response.control_category
        ? convertResponseToControlCategoryDetailsDTO(
            response.control_category,
            this.lang
          )
        : null,
      owner_unit: response.owner_unit,
      owner: this.userConv.resObjToUserMinimalDTO(response.owner),
      control_implementation_status: response.control_implementation_status
        ? convertResponseToControlImplementationStatusDetailsDTO(
            response.control_implementation_status,
            this.lang
          )
        : null,
      control_efficiency_status: response.control_efficiency_status
        ? convertResponseToControlEfficiencyStatusDetailsDTO(
            response.control_efficiency_status,
            this.lang
          )
        : null,
      efficiency_score: response.efficiency_score,
      review_frequency: response.review_frequency
        ? convertResponseToReviewFrequencyDTO(
            response.review_frequency,
            this.lang
          )
        : null,
      last_review_date: response.last_review_date,
      next_review_date: response.next_review_date,
      last_review_note: response.last_review_note,
      test_responsible_user: this.userConv.resObjToUserMinimalDTO(
        response.test_responsible_user
      ),
      test_frequency: response.test_frequency
        ? convertResponseToReviewFrequencyDTO(
            response.test_frequency,
            this.lang
          )
        : null,
      last_test_date: response.last_test_date,
      next_test_date: response.next_test_date,
      implemented_at: response.implemented_at,
      latest_control_test: response.latest_control_test
        ? convertResponseToControlTestDetailsDTO(
            response.latest_control_test,
            this.lang
          )
        : null,
      status: response.status
        ? convertResponseToLifeCycleStatusDetailsDTO(response.status, this.lang)
        : null,
      created_by: this.userConv.resObjToUserMinimalDTO(response.created_by),
      updated_by: this.userConv.resObjToUserMinimalDTO(response.updated_by),
      created_at: response.created_at,
      updated_at: response.updated_at ? response.updated_at : null,
    };
  }

  resToControlMinimalDetailsDTO(response: any): ControlMinimalDetailsDTO {
    return {
      id: response.id,
      reference_number: response.reference_number,
      code: response.code,
      title: response[`title_${this.lang}`],
      description: response.description,
    };
  }
}
